
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
//import {ActionsRe} from "@/store/enums/StoreResearchEnums";

export default defineComponent({
  props: 
  {
      id: { required: true }
  },
  name: "categories-details",
  components: {
    //CategoryAttributeList,
   // CategoryAttributeRangeList
  },
  methods: {
    
  },
  setup(props) {

    var ids = ref(props.id);
    const store = useStore();
    var tag_list: any[] = []

   interface ViewNews {
    user: {
        avatar: string;
        news_image_name: string,
      },  
      news_id,
      news_title,
      category_name,
      news_description,
      news_short_description,
      parent_category_name,
      tag_ids,
      news_type,
      minutes_to_read,
      active : {
        label :string;
        color :string;
      }
    };   

    var resultsView = ref<ViewNews>();
    const loadingData = ref<boolean>(true);
    const count = ref(0);

    resultsView.value = {
      user: {
        avatar: "",
        news_image_name: "",
      },  
      news_id: "",
      news_title: "",
      category_name: "",
      news_description:"",
      news_short_description: "",
      parent_category_name: "",
      tag_ids: "",
      news_type: "",
      minutes_to_read: "",
      active : {
        label :"",
        color :"",
      }
    }

    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

     const refreshData =() => {      
      paginationData.value['activePage']  = 1;      
      getNewsList()
      
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getNewsList()
     
    }
    

    const getNewsList = async () => {
     
      try {

      var values = {"news_id": props.id }
        await store.dispatch(Actions.CUST_NEWS_EDIT, values).then(({ data }) => {

        var status_label = ""
        var status_color = ""
        var active_label = ""
        var active_color = ""

        if (data.active){
            active_label = "Yes";
            active_color = "success";                  
        }else{
            active_label = "No";
            active_color = "danger";
        }
        resultsView.value = {
          news_id :  data.news_id,
          news_title : data.news_title,
          news_description: data.news_description,
          news_short_description: data.news_short_description,
          category_name : data.category_name,
          parent_category_name : data.parent_category_name,
          tag_ids : data.news_tags_json,
          news_type : data.news_type,
          minutes_to_read : data.minutes_to_read,
          user : {
                news_image_name : data.news_image_name,
                avatar : data.news_image_path,
              },
          active: {
            label: active_label,
            color: active_color
          },
        }
      
      })
      .catch(({ response }) => {

        console.log(response);

      });
        
      } catch (e) {
        console.log(e);
      }
    };        
    
    onMounted( async () => {
      await getNewsList();
      setCurrentPageBreadcrumbs("News Details", ["News"]);
    });     


    return {
      changePageChange,
      refreshData,
      resultsView,
      loadingData,
      ids,
    };

  }
});
